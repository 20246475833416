@import-normalize;

// variables
$primary: #264f46;
$accent: #e9cbc1;
$tertiary: #d39b92;

// utilities

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1,
h2 {
  margin: 0;
}

body {
  background-color: $accent;
  color: $primary;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

input {
  background-color: $accent;
  color: $tertiary;
  border: 2px solid $tertiary;
  padding: 0.3rem 0.6rem;
  border-radius: 0.25rem;
}

button,
.btn {
  background-color: $accent;
  border: 2px solid $tertiary;
  color: $tertiary;
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0;
  transition: all 0.3s ease;
  width: 120px;
  height: 40px;
  font-size: 0.78rem;

  &:hover {
    background-color: $tertiary;
    color: $accent;
    cursor: pointer;
  }

  &.primary {
    background-color: $primary;
    color: $accent;
    border: 2px solid $primary;

    &:hover {
      background-color: darken($primary, 6%);
    }
  }

  &.circle {
    width: 35px;
    height: 35px;
    border-radius: 9999px;
    font-size: 1.5rem;
  }
}

button[disabled=""] {
  background-color: $tertiary;
  color: $accent;
  opacity: 0.6;

  &:hover {
    cursor: not-allowed;
  }
}

.modal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: transparentize($tertiary, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.inner {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

// layout

// LOGIN

.login {
  display: flex;

  .sidebar {
    width: 30%;
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

.login-form {
  background-color: $primary;
  color: $accent;
  border-radius: 0.25rem;
  min-height: 300px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 0 0 4px 4px $tertiary;

  h2,
  input {
    margin-bottom: 1rem;
  }

  input,
  .btn {
    background-color: $primary;
    color: $tertiary;
    border: 2px solid $tertiary;
  }
}

// DASHBOARD

.dashboard {
  display: flex;
  .dashboard-sidebar {
    height: 100vh;
    width: 23.3%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    img {
      width: 80%;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 0.5rem;
      line-height: 1.5;
    }
  }
}

.dashboard-main {
  width: 76%;

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .new-brand {
    position: absolute;
    bottom: 2%;
    right: 2%;

    .btn {
      margin-right: 0.5rem;
    }
  }
}

.new-brand-form {
  background-color: $accent;
  width: 80%;
  border-radius: 0.25rem;
  min-height: 600px;
  padding: 0.5rem 4rem;

  .item-wrapper {
    margin-top: 0.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $tertiary;

    .brand-title-input {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid $tertiary;
      margin-bottom: 0.5rem;
      color: $primary;
      text-align: center;
      font-size: 1.4rem;
    }

    .icon-list {
      display: grid;
      margin-top: 2rem;
      margin-bottom: 2rem;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 1.5rem;
      width: 300px;
      margin-right: auto;
      margin-left: auto;
      justify-items: center;
      align-items: center;
      text-align: center;

      .social-icon {
        width: 32px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .label {
      font-weight: 600;
      color: $tertiary;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .role {
      color: $tertiary;
      font-weight: 600;
      display: flex;
      width: 100px;
      margin-right: auto;
      margin-left: auto;
      justify-content: center;
      align-items: center;

      p {
        margin-right: 0.5rem;
        margin-left: 0.5rem;

        &.active {
          color: $primary;
        }
      }
    }

    .select-items {
      margin-top: 1rem;
    }

    .select-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      width: 280px;
      margin-right: auto;
      margin-left: auto;

      .prenum {
        background-color: $tertiary;
        color: $accent;
        width: 20px;
        height: 30.33px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      select {
        appearance: none;
        background-color: $accent;
        color: $tertiary;
        border: 2px solid $tertiary;
        padding: 0.26rem;
        width: 160px;
        text-align: center;
        font-weight: 600;

        &:after {
          content: "";
          width: 0.8rem;
          height: 0.5rem;
          background-color: $tertiary;
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        }
      }
    }

    span {
      display: block;
      margin-bottom: 0.5rem;

      input {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  .btns {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    button,
    input {
      margin-right: 1rem;
    }
  }
}

.brand-items {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-around;

  // @media screen and (min-width: 1400px) {
  //   grid-template-columns: repeat(3, 1fr);
  // }

  @media screen and (min-width: 1850px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .item {
    width: 400px;
    background-color: $primary;
    color: $accent;
    padding: 1rem 2rem;
    border-radius: 0.25rem;
    transition: all 0.5s ease;

    h3 {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    .projects {
      .top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: 1rem;

        .circle {
          background-color: $primary;
        }
      }
    }

    .project-listing {
      a {
        margin-bottom: 0.5rem;
        display: block;
        color: $accent;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.new-project-form {
  border-radius: 0.25rem;
  background-color: $accent;
  color: $tertiary;
  padding: 1.25rem;
  width: 80%;
  margin-bottom: 1rem;

  input {
    width: 80%;
    color: $primary;
    font-weight: 600;
  }

  .label {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .btn {
    margin-top: 1rem;
    display: block;
  }
}

// PROJECT

.project {
  display: flex;

  .sidebar {
    width: 350px;
    padding-left: 1.25rem;
    padding-top: 2rem;
    padding-right: 1rem;

    .sidebar-image {
      width: 185px;
      margin-bottom: 2rem;
    }

    .sidebar-body,
    .body {
      line-height: 1.6;
      p {
        margin-bottom: 1.5rem;
      }
    }

    .listing {
      width: 90%;
      line-height: 1.6;
    }

    h3 {
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;

      &.active {
        font-size: 1.3rem;
      }

      &.inactive {
        font-size: 1.2rem;
        color: $tertiary;
        opacity: 0.9;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      &.disabled {
        opacity: 0.3;
        &:hover {
          cursor: not-allowed;
          opacity: 0.1;
        }
      }
    }

    h1 {
      line-height: 1;
      margin-bottom: 1.2rem;
      margin-top: 1.1rem;
    }
  }
}

.content-wrapper {
  width: 100%;
  .project-content {
    padding-top: 2rem;
    width: 100%;
    margin-bottom: 1rem;
    min-height: 90vh;
  }

  .bottom-action {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;

    .btn.continue {
      border: none;
      text-transform: uppercase;
      font-weight: 600;
      color: $primary;

      &.disabled {
        opacity: 0.4;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

// PERSONA

.persona-list {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.persona-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.persona-form {
  width: 350px;
  display: flex;
  flex-direction: column;
  background-color: $primary;
  padding: 1.5rem;
  border-radius: 0.25rem;
  text-align: center;

  input {
    background-color: $primary;
    color: $accent;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px solid $tertiary;
    border-radius: 0;
    text-align: center;
  }

  .label {
    text-transform: uppercase;
    font-weight: 600;
    color: $tertiary;
    margin-bottom: 1rem;
  }

  .btn {
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
    display: block;
    background-color: $primary;
    border: 2px solid $tertiary;
    color: $tertiary;
  }
}

.persona-item {
  margin-right: 3rem;
  margin-bottom: 0.5rem;
  width: 400px;
  height: 560px;
  position: relative;
  border: 2px dotted $tertiary;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-card {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 350px;
    border-radius: 0.25rem;
    padding: 1rem 0.25rem;
    border: 2px solid $tertiary;

    h1,
    h3 {
      color: $accent;
      font-weight: 400;
      text-transform: uppercase;
      border-bottom: 2px solid $tertiary;
      width: 70%;
      margin-right: auto;
      margin-left: auto;
      mix-blend-mode: screen;
    }

    .label {
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 0.25rem;
      margin-bottom: 1.5rem;
      color: $tertiary;
      font-size: 0.9rem;
      mix-blend-mode: screen;
    }
  }

  .goal-listing {
    margin-top: 1.25rem;

    .title {
      font-weight: 600;
      font-style: italic;
      color: $tertiary;
      margin-bottom: 1rem;
    }

    .goal-item,
    .add-goal,
    .goal-form {
      width: 350px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $tertiary;
      color: $accent;
      text-transform: uppercase;
      border-radius: 1rem;
      margin-bottom: 0.8rem;

      p {
        mix-blend-mode: screen;
      }
    }

    .add-goal {
      background-color: $accent;
      color: $tertiary;
      font-size: 1.25rem;
    }

    .goal-form {
      border: none;
      justify-content: space-between;
      height: 28px;

      input {
        border-radius: 1rem;
        width: 87%;
        text-align: center;
        color: $tertiary;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .add-persona-button {
    position: absolute;
    height: 560px;
    width: 25px;
    top: -2px;
    bottom: 0;
    right: -25px;
    color: $accent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 0.15rem;
    background-color: $tertiary;
  }
}

.persona-selector {
  select {
    color: #fff;
    min-width: 170px;
    height: 45px;
    border: 2px solid $tertiary;
    border-radius: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
  }
}

.persona-breakdown {
  width: 650px;
  color: $primary;

  .top-section {
    height: 85px;
    border: 2px solid $tertiary;
    display: flex;
    border-radius: 0.75rem;
    justify-content: flex-start;
    align-items: center;
  }

  .bottom-section {
    height: 60px;
    border: 2px solid $tertiary;
    background-color: $tertiary;
    border-radius: 0.75rem;
    margin-top: 0.35rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    p {
      color: $primary;
      font-size: 1rem;

      .persona-name {
        color: $accent;
        padding: 0.25rem;
      }

      .action {
        text-decoration: underline;
      }
    }
  }

  .persona-icon {
    width: 45px;
    margin-left: 1rem;
    margin-right: 1.5rem;
  }

  .title-section {
    text-transform: uppercase;
    margin-right: 1rem;
    padding-right: 2rem;
    border-right: 1px solid $accent;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
    h3 {
      font-size: 1rem;
      font-weight: 400;
      font-style: italic;
    }
  }

  .goals {
    width: 330px;
    display: flex;

    .title {
      text-transform: uppercase;
      color: $tertiary;
      font-weight: 500;
    }

    .goal {
      margin-left: 1rem;
      max-width: 70px;
      font-size: 0.85rem;
      text-transform: uppercase;
    }
  }

  .arrow {
    transition: all 0.4s ease;

    &:hover {
      transform: translateY(5px);
    }

    &.down {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) translateY(5px);
      }
    }
  }
}

// STEP 2 - CHALLENGES

.challenge {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px dotted $tertiary;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  .selector-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    p {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.1rem;
    }

    .persona-selector {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .subtext {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
    font-style: italic;
    font-weight: 600;
    color: $tertiary;
  }

  .title {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 1rem;
  }
}

.opportunity {
  @extend .challenge;
  border-bottom: none;
}

.concerns {
  .concern-form-btn,
  .concern-item,
  .concern-form {
    width: 350px;
    height: 35px;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
  }

  .concern-item {
    color: $accent;
    border: 2px solid $tertiary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    p {
      mix-blend-mode: screen;
    }
  }

  .concern-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      height: 35px;
      border-radius: 0.75rem;
      width: 80%;
      font-weight: 600;
    }
  }
}

// STEP 3 - GTB

.gtb-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 82.5vh;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    h3 {
      color: $tertiary;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.4rem;
      margin-right: 1rem;
    }

    .input-wrapper {
      width: 450px;

      input {
        width: 400px;
        border: none;
        border-radius: 0;
        border-bottom: 4px solid $tertiary;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 1.3rem;
        color: $primary;
        font-weight: 500;
      }

      .label {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        color: $tertiary;
        font-style: italic;
      }
    }
  }
}

// STEP 4 - GUIDED BRAIN DUMP

.braindump {
  display: flex;
  flex-direction: column;
  align-items: center;

  .persona-breakdown {
    margin-bottom: 3rem;
  }
}

.braindump-wrapper {
  min-height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.braindump-item-wrapper {
  width: 400px;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .disclaimer {
    color: $tertiary;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.6;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .underline {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.braindump-item {
  width: 95%;
  border: 1px solid $tertiary;
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  height: 335px;

  .pretitle {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $tertiary;
    font-weight: 500;
  }

  h4 {
    color: $tertiary;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  .topic,
  .topic-form,
  .add-topic {
    width: 80%;
    border-radius: 0.75rem;
    height: 35px;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .topic-form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: 80%;
      font-weight: 500;
      color: darken($tertiary, 10);
    }
  }

  .topic {
    border: 2px solid $tertiary;
    color: $accent;
    font-size: 1rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// STEP 5 - THEME BOARD

.new-theme-form {
  background-color: $primary;
  color: $accent;
  height: 400px;
  width: 500px;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 8px 2px $primary;

  .intro {
    font-weight: 500;

    h3 {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 1rem;
      font-style: italic;
      font-weight: 400;
    }
  }

  input {
    background-color: $primary;
    color: $accent;
    border: 2px solid $tertiary;
    margin-bottom: 0.5rem;
  }

  .btns {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    width: 70%;
    button {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      background-color: $tertiary;
      color: $accent;

      &.alt {
        background-color: $primary;
        color: $tertiary;
      }
    }
  }
}

.can-drop {
  background-color: rgba(0, 0, 0, 0.05);
}

.is-over {
  background-color: rgba(0, 0, 0, 0.25);
}

.theme-board {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;

  .theme-list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;

    @media screen and (min-width: 1700px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.topics {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(10, 100px);
  padding: 1.7rem;

  @media screen and (max-width: 1300px) {
    grid-template-columns: auto auto;
  }

  @media screen and (min-width: 1500px) {
    grid-template-columns: repeat(4, auto);
  }

  @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(9, 140px);
  }
}

.theme {
  border: 1px solid $tertiary;
  border-radius: 0.75rem;
  padding: 0.5rem;
  width: 600px;
  position: relative;
  margin-bottom: 1.5rem;

  .title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
    margin-bottom: 1rem;

    p {
      text-transform: uppercase;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .desc {
      color: $tertiary;
    }
  }

  .topic-list {
    padding: 0.5rem;
    min-height: 250px;
    display: flex;
    flex-wrap: wrap;
  }

  .sorted-topics {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid $tertiary;
  }

  .child-drop-zone {
    min-height: 150px;
    width: 33.333%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      margin-top: 0.5rem;
      font-size: 1rem;
      color: $tertiary;
      font-weight: 500;
    }

    &:nth-child(2) {
      border-left: 2px solid $tertiary;
      border-right: 2px solid $tertiary;
    }
  }

  .add-theme {
    width: 100%;
    background-color: $tertiary;
    color: $accent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 30px;
    border-radius: 0.75rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.topic-item {
  width: 110px;
  height: 80px;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: 2px 2px 5px 2px $tertiary;

  p {
    color: $accent;
    mix-blend-mode: hard-light;
  }
}

// STEP 6 - SOCIAL CHANNEL LINKING

.social-linking {
  min-height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme-item {
  margin-top: 2rem;
  width: 700px;
  border: 1px solid $tertiary;
  border-radius: 0.75rem;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .top-bar {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $tertiary;

    h4 {
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 0.45rem;
    }

    .pretitle {
      color: $tertiary;
    }
  }

  .arrow-btn {
    position: absolute;
    top: -2px;
    bottom: -2px;
    width: 15px;
    height: 101%;
    background-color: $tertiary;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &.btn-prev {
      left: -10px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.btn-next {
      right: -10px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.cannot-move {
      filter: grayscale(0.4);
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .topic-list-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .title {
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      border-bottom: 2px solid $tertiary;
      text-transform: uppercase;
      font-weight: 500;
      color: $tertiary;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    .disclaimer {
      text-align: center;
      font-weight: 400;
      color: $tertiary;
      font-style: italic;
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    .topic-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.65rem;
      margin-bottom: 2rem;

      .topic-item-wrapper {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .channel-list {
          display: grid;
          grid-template-columns: repeat(4, 24px);
          grid-gap: 0.6rem;
          .social-icon {
            width: 24px;
          }
        }
      }
    }
  }
}

// STEP 7 - SUMMARY

.summary-page {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.summary {
  @extend .theme-item;
  min-height: 500px;

  .topic-listing {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .title {
      width: 100%;
      font-size: 1rem;
      background-color: $primary;
      margin-bottom: 0.25rem;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .topic-line-items {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .topic-line-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 0.9rem;
      border-bottom: 1px solid $tertiary;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &.header {
        color: $tertiary;
        padding-top: 0;
        padding-bottom: 0;
      }

      .icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 18px;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }
      }
    }
  }
}

.selector-btn {
  padding: 0.5rem;
  border: 1px solid black;
  margin-right: 0.5rem;
  color: white;
}

.name-callout {
  padding: 0.15rem;
}

.new-user {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  h1 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
  }

  .alert {
    .btns {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      margin-top: 1rem;

      .primary {
        margin-right: 0.5rem;
      }
    }
  }

  .form {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 0.25rem;
    background-color: $primary;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $accent;

    label {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    input {
      background-color: $accent;
      border-color: $tertiary;
      color: $primary;
    }

    .btn {
      color: $primary;
      &.one {
        margin-right: 0.5rem;
      }
    }
  }
}
